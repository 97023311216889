import React, { useState, useEffect, useRef } from 'react';

interface TypingEffectProps {
    text: string;
    onComplete: () => void;
}

const TypingEffect: React.FC<TypingEffectProps> = ({ text, onComplete }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const words = useRef(text.split(' '));
    const currentIndex = useRef(0);
    const typingRef = useRef<HTMLSpanElement | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );

        const typingElement = typingRef.current;

        if (typingElement) {
            observer.observe(typingElement);
        }

        return () => {
            if (typingElement) {
                observer.unobserve(typingElement);
            }
        };
    }, []);

    useEffect(() => {
        if (!isVisible) return;

        const wordsCurrent = words.current;
        const typeNextWord = () => {
            if (currentIndex.current < wordsCurrent.length -1) {
                // console.log(currentIndex.current)
                // console.log(wordsCurrent[currentIndex.current])
                setDisplayedText((prev) => prev + (prev ? ' ' : '') + wordsCurrent[currentIndex.current]);
                currentIndex.current += 1;
                setTimeout(typeNextWord, 100); // Adjust the delay as needed
            } else {
                setTimeout(onComplete, 1000);
            }
        };

        typeNextWord();

        return () => {
            currentIndex.current = wordsCurrent.length; // Prevent further updates if component unmounts
        };
    }, [isVisible, onComplete]);

    return <span ref={typingRef} className="ai shown">{displayedText}</span>;
};

export default TypingEffect;