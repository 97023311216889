import { useState } from 'react';
import './accordion-card.css';

const AccordionCard = ({ title, description }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={`card ${isVisible ? 'active' : ''}`}>
      <div className="accordion-header" onClick={toggleVisibility}>
        <div className="why-training-peaks">{title}</div>
        <img
          className="icbaseline-minus-icon"
          alt=""
          src={isVisible ? '/deexpand-icon.svg' : '/expand-icon.svg'}
        />
      </div>
      <div className={`accordion-des ${isVisible ? '' : 'hidden'}`}>
        {description}
      </div>
    </div>
  );
};

export default AccordionCard;
