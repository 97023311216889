import IChatDay from '../../classes/IChatDay';
import { chatsActionTypes } from '../actions/actionTypes';

export interface IChat {
  id: string;
  message: string;
  messageFrom: string;
  timestamp: Date;
  type: string;
  special?: string;
  is_liked?: boolean | null;
  main_tab_enum?: string;
  application_mode?: string;
  options?: string[];
  isStream?: boolean;
  streamFinished?: boolean;
}

export interface ChatFromBackend {
  id: string;
  message_from: string;
  timestamp: Date;
  message: string;
  type: string;
  special?: string;
  is_liked?: boolean | null;
  main_tab_enum?: string;
  application_mode?: string;
  options?: string[];
}

export interface ChatDayFromBackend {
  day: string;
  data: ChatFromBackend[];
}

export interface ChatsState {
  chats: IChatDay[];
  loading: boolean;
  error: boolean;
  errorMessage: string;
}

const initialState: ChatsState = {
  loading: false,
  error: false,
  chats: [],
  errorMessage: '',
};

type Action =
  | { type: 'SEND_CHAT_SUCCESS'; chats: IChatDay[] }
  | { type: 'GET_CHATS_SUCCESS'; chats: IChatDay[] }
  | { type: 'GET_CHATS_START' };

const reducer = (state: ChatsState = initialState, action: Action) => {
  switch (action.type) {
    case chatsActionTypes.GET_CHATS_START:
      return {
        ...state,
        loading: true,
        logInError: false,
        errorMessage: '',
      };

    case 'GET_CHATS_SUCCESS':
      // Ensure there is at least a "Today" in the chats even if no data
      return {
        ...state,
        loading: false,
        chats: action.chats,
      };

    case chatsActionTypes.SEND_CHAT_START:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        error: false,
      };

    case 'SEND_CHAT_SUCCESS':
      // TODO add returned chat to state.chats
      return {
        ...state,
        chats: state.chats.concat(action.chats),
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
