import './LoadingMessage.css';
const LoadingMessage = () => (
  <>
    <span className="ai shown">
      <div className="loader"></div>
    </span>
  </>
);

export default LoadingMessage;
