const emailRegex = new RegExp(
  '(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$)',
);
const phoneRegex = new RegExp('^[0-9() +-]+$');

const checkIsValid = (value: any, rules: any) => {
  let isValid = true;

  if (rules && rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules && rules.isDate) {
    isValid = !!(value as Date).getDate() && isValid;
  }

  if (rules && rules.isInThePast) {
    // Date less than current time
    isValid = new Date() > (value as Date) && isValid;
  }

  if (rules && rules.isNumeric) {
    isValid = typeof value === 'number' && isValid;
  }

  if (rules && !!rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules && rules.email) {
    isValid = emailRegex.test(value) && isValid;
  }

  if (rules && rules.phoneNumber) {
    isValid = phoneRegex.test(value) && isValid;
  }

  return isValid;
};

export default checkIsValid;
