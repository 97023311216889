import React from 'react';

const SubscriptionSuccess: React.FC = () => {
  return (
    <div>
      <h1>Subscription Successful!</h1>
      <p>Thank you for subscribing to Standard Team Skip Plan.</p>
    </div>
  );
};

export default SubscriptionSuccess;
