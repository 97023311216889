import { SubscriptionType } from '../../classes/SubscriptionType';
import { userActionTypes } from '../actions/actionTypes';

export interface UsersState {
  loggedIn: boolean;
  active: boolean;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  logInError: boolean;
  signupError: boolean;
  analysingTpData: boolean;
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
  trainingpeaks_athlete_id: string;
  subscription: SubscriptionType;
  utilized_trial: boolean;
}

const initialState: UsersState = {
  loggedIn: false,
  active: false,
  loading: false,
  error: false,
  errorMessage: '',
  logInError: false,
  signupError: false,
  analysingTpData: false,
  accessToken: '',
  firstName: '',
  lastName: '',
  email: '',
  trainingpeaks_athlete_id: '',
  subscription: {
    active: false,
    plan: 'FREE',
  },
  utilized_trial: false,
};

type Action =
  | { type: typeof userActionTypes.LOGIN_START }
  | { type: typeof userActionTypes.SIGNUP_START }
  | { type: typeof userActionTypes.SIGNUP_SUCCESS }
  | { type: typeof userActionTypes.SIGNUP_ERROR; errorMessage: string }
  | { type: typeof userActionTypes.LOGOUT }
  | {
      type: typeof userActionTypes.LOGIN_SUCCESS;
      accessToken: string;
      first_name: string;
      last_name: string;
      email: string;
      trainingpeaks_athlete_id: string;
      subscription: SubscriptionType;
      utilized_trial: boolean;
    }
  | {
      type: typeof userActionTypes.UPDATE_USER_SUCCESS;
      payload: { field: keyof UsersState; value: any }[];
    }
  | { type: typeof userActionTypes.RESET_USER_STATE };

const reducer = (state: UsersState = initialState, action: Action) => {
  switch (action.type) {
    case userActionTypes.LOGIN_START:
      return {
        ...state,
        loading: true,
        logInError: false,
        signupError: false,
        errorMessage: '',
        errorCode: null,
      };

    case userActionTypes.SIGNUP_START:
      return {
        ...state,
        loading: true,
        signupError: false,
        errorMessage: '',
        errorCode: null,
      };

    case userActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        accessToken: action.accessToken,
        firstName: action.first_name,
        lastName: action.last_name,
        email: action.email,
        trainingpeaks_athlete_id: action.trainingpeaks_athlete_id,
        subscription: {
          id: action.subscription.id ?? state.subscription.id,
          active: action.subscription.active,
          plan: action.subscription.plan,
          status: action.subscription.status ?? state.subscription.status,
          created_date:
            action.subscription.created_date ?? state.subscription.created_date,
          updated_date:
            action.subscription.updated_date ?? state.subscription.updated_date,
          trial_end_date:
            action.subscription.trial_end_date ??
            state.subscription.trial_end_date,
          trial_start_date:
            action.subscription.trial_start_date ??
            state.subscription.trial_start_date,
          current_period_start_date:
            action.subscription.current_period_start_date ??
            state.subscription.current_period_start_date,
          current_period_end_date:
            action.subscription.current_period_end_date ??
            state.subscription.current_period_end_date,
          start_date:
            action.subscription.start_date ?? state.subscription.start_date,
          end_date: action.subscription.end_date ?? state.subscription.end_date,
          cancel_date:
            action.subscription.cancel_date ?? state.subscription.cancel_date,
        },
        utilized_trial: action.utilized_trial,
      };

    case userActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
      };

    case userActionTypes.SIGNUP_ERROR:
      return {
        ...state,
        loading: false,
        signupError: true,
        errorMessage: action.errorMessage,
      };

    case userActionTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };

    case userActionTypes.UPDATE_USER_SUCCESS:
      console.log('Action payload:', action.payload);
      const updatedState = { ...state };
      action.payload.forEach(({ field, value }) => {
        (updatedState as any)[field] = value;
      });
      console.log('Updated state:', updatedState);
      return updatedState;

    case userActionTypes.RESET_USER_STATE:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
