import { connectStravaActionTypes } from '../actions/actionTypes';

export interface StravaConnectState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  success: boolean;
}

const initialState: StravaConnectState = {
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

type Action =
  | { type: typeof connectStravaActionTypes.CONNECT_STRAVA_START }
  | { type: typeof connectStravaActionTypes.CONNECT_STRAVA_SUCCESS }
  | {
      type: typeof connectStravaActionTypes.CONNECT_STRAVA_ERROR;
      errorMessage: string;
    };

const reducer = (state: StravaConnectState = initialState, action: Action) => {
  switch (action.type) {
    case connectStravaActionTypes.CONNECT_STRAVA_START:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        error: false,
      };

    case connectStravaActionTypes.CONNECT_STRAVA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        error: false,
        success: true,
      };

    default:
      return state;
  }
};

export default reducer;
