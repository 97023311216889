import { IAppState } from '../../classes/IAppState';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as actionCreators from '../../store/actions';
import { connect, useSelector } from 'react-redux';
import { useEffect } from 'react';

interface TpCallbackProps {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  success: boolean;
  tpConnect: (code: string) => void;
}

const TpCallback = (props: TpCallbackProps) => {
  const queryParameters = new URLSearchParams(window.location.search);

  // const state = queryParameters.get("state")
  const code = queryParameters.get('code');
  console.log(code);

  if (code && code?.length) {
    // Code exists, send to our backend to try and connect strava
    props.tpConnect(code);
    // window.close();
  }

  let success = useSelector((state: IAppState) => state.tpConnect.success);

  useEffect(() => {
    if (success) {
      window.close();
    }
  }, [success]);

  return (
    <>
      {success ? (
        <h3>
          TrainingPeaks connected, you may close this window/tab and return to
          TeamSkip
        </h3>
      ) : (
        <h3>
          This Training Peaks account is already connected to another account,
          you may close this window/tab and return to TeamSkip
        </h3>
      )}
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    loading: state.tpConnect.loading,
    error: state.tpConnect.error,
    errorMessage: state.tpConnect.errorMessage,
    success: state.tpConnect.success,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    tpConnect: (code: string) => dispatch(actionCreators.tpConnect(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TpCallback);
