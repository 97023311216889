import { useState, useEffect, useCallback } from 'react';
import { IAppState } from '../../classes/IAppState';
import PricingPopup from '../Popups/PricingPopup';
import PortalPopup from '../Popups/PortalPopup';
import './profile-dropdown.css';

//

interface ProfileDropdownProps {
  logout: () => void;
  user: IAppState['users'];
}

const ProfileDropdown2 = (props: ProfileDropdownProps) => {
  let user = props.user;

  //

  const [isPricingPopupOpen, setPricingPopupOpen] = useState(false);

  const openPricingPopup = useCallback(() => {
    setPricingPopupOpen(true);
  }, []);

  const closePricingPopup = useCallback(() => {
    setPricingPopupOpen(false);
  }, []);

  //
  useEffect(() => {
    if (user.trainingpeaks_athlete_id && !user.subscription.status) {
      setPricingPopupOpen(true);
    }
    if (user.trainingpeaks_athlete_id && user.subscription.status) {
      setPricingPopupOpen(false);
    }
  }, [user.trainingpeaks_athlete_id, user.subscription.status]);

  return (
    <div className="dropdown2">
      {user.trainingpeaks_athlete_id && (
        <>
          <div className="header-frame1">
            <div className="pic-frame" />
            <div className="intro-frame">
              <div className="name">
                {user.firstName} {user.lastName}
              </div>
              <div className="plan">Current Plan: {user.subscription.plan}</div>
            </div>
          </div>
          <button className="upgrade-button" onClick={openPricingPopup}>
            <div className="upgrade-text">Upgrade Plan</div>
            {isPricingPopupOpen && (
              <PortalPopup
                overlayColor="rgba(113, 113, 113, 0.3)"
                placement="Centered"
                onOutsideClick={
                  user.subscription.active ? closePricingPopup : () => {}
                }
              >
                <PricingPopup onClose={closePricingPopup} user={user} />
              </PortalPopup>
            )}
          </button>
        </>
      )}
      <div className="options-frame">
        <button className="view-profile-button">
          <div className="lead-icon-s">
            <img
              className="iconoutlinelayer"
              alt=""
              src="/iconoutlinelayer.svg"
            />
          </div>
          <div className="button-frame">
            <div className="view-profile">View Profile</div>
          </div>
        </button>
      </div>
      <div className="options-frame">
        <button className="view-profile-button">
          <div className="lead-icon-s">
            <img
              className="iconoutlinelayer"
              alt=""
              src="/iconoutlinelayer.svg"
            />
          </div>
          <div className="button-frame">
            <div className="view-profile">Settings and Preferences</div>
          </div>
        </button>
      </div>
      <div className="options-frame">
        <button className="view-profile-button">
          <div className="lead-icon-s">
            <img
              className="iconoutlinelayer"
              alt=""
              src="/iconoutlinelayer.svg"
            />
          </div>
          <div className="button-frame" onClick={props.logout}>
            <div className="view-profile">Logout</div>
          </div>
        </button>
      </div>
      <div className="footer-frame">
        <button className="privacy-policy">Privacy Policy</button>
        <div className="divider-vertical" />
        <button className="privacy-policy">Terms of Service</button>
      </div>
    </div>
  );
};

export default ProfileDropdown2;
