import { Dispatch } from 'redux';
import axios from '../../axios-skip';
import { SubscriptionType } from '../../classes/SubscriptionType';
import { userActionTypes } from './actionTypes';

const loginStart = () => {
  return {
    type: userActionTypes.LOGIN_START,
  };
};

interface LoginSuccessResponse {
  jwt: string;
  first_name: string;
  last_name: string;
  email: string;
  trainingpeaks_athlete_id: string;
  subscription: SubscriptionType;
  utilized_trial: boolean;
}

const loginSuccess = (data: LoginSuccessResponse) => {
  localStorage.setItem('accessToken', data['jwt']);

  return {
    type: userActionTypes.LOGIN_SUCCESS,
    // userId: data['user'],
    accessToken: data['jwt'],
    first_name: data['first_name'],
    last_name: data['last_name'],
    email: data['email'],
    trainingpeaks_athlete_id: data['trainingpeaks_athlete_id'],
    subscription: data['subscription'],
    utilized_trial: data['utilized_trial'],
    // role: data['scope'][0],
  };
};

const loginError = (error: string) => {
  return {
    type: userActionTypes.LOGIN_ERROR,
    errorMessage: error,
  };
};

export const login = (
  token: string,
  first_name: string,
  last_name: string,
  email: string,
  trainingpeaks_athlete_id: string,
  subscription: SubscriptionType,
  utilized_trial: boolean,
) => {
  return async (dispatch: Dispatch) => {
    dispatch(loginStart());
    try {
      dispatch(
        loginSuccess({
          jwt: token,
          first_name: first_name,
          last_name: last_name,
          email: email,
          trainingpeaks_athlete_id: trainingpeaks_athlete_id,
          subscription: subscription,
          utilized_trial: utilized_trial,
        }),
      );
      // const response = await axios.post('login', { email, password });
      // dispatch(loginSuccess(response.data));
    } catch (e) {
      dispatch(loginError('There was an error logging in, please try again'));
    }
  };
};

export const updateAthlete = (updates: { field: string; value: any }[]) => {
  return {
    type: userActionTypes.UPDATE_USER_SUCCESS,
    payload: updates,
  };
};

export const getRefreshToken = () => {
  return async (dispatch: Dispatch) => {
    try {
      await axios.post('new-refresh');
      console.log('new r done');
    } catch (e: any) {
      console.log('new r failed');
    }
  };
};

const doLogout = () => {
  console.log('in doLogout');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('email');
  return {
    type: userActionTypes.LOGOUT,
  };
};

export const logout = () => {
  console.log('in logout action');
  return async (dispatch: Dispatch) => {
    try {
      await axios.post('logout');
      dispatch(doLogout());
    } catch (e: any) {}
  };
};

const signupStart = () => {
  return {
    type: userActionTypes.SIGNUP_START,
  };
};

const signupSuccess = (data: LoginSuccessResponse) => {
  localStorage.setItem('accessToken', data['jwt']);

  localStorage.setItem('firstName', data['first_name']);
  localStorage.setItem('lastName', data['last_name']);
  localStorage.setItem('email', data['email']);

  return {
    type: userActionTypes.LOGIN_SUCCESS,
    first_name: data['first_name'],
    last_name: data['last_name'],
    email: data['email'],
  };
};

const signupError = (error: string) => {
  return {
    type: userActionTypes.SIGNUP_ERROR,
    errorMessage: error,
  };
};

export const signup = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(signupStart());
    try {
      const response = await axios.post('signup', { email, password });
      dispatch(signupSuccess(response.data));
    } catch (e: any) {
      if (e.response && e.response.data.detail) {
        dispatch(signupError(e.response.data.detail));
      } else {
        dispatch(
          signupError('There was an error logging in, please try again'),
        );
      }
    }
  };
};

export const resetUserState = () => {
  return (dispatch: Dispatch): Promise<void> => {
    return new Promise((resolve) => {
      dispatch({ type: userActionTypes.RESET_USER_STATE });
      resolve();
    });
  };
};
