import IFormItem from "../../classes/IFormItem";
import React, {useState} from "react";
import checkIsValid from "../../util/checkIsValid";
import Input from "../FormField/Input";
import Button from "../FormField/Button";
import TypingEffect from "./TypingEffect";

interface ITarForm {
    name: IFormItem,
    email: IFormItem,
    // eventRelationship: IFormItem,
    // choiceOfEvent: IFormItem,
    // purpose: IFormItem,
    message: IFormItem
}

interface TarFormProps {
    sendMessage: (message: string) => void;
}

const TarForm = (props: TarFormProps) => {
    const initialTarForm: ITarForm = {
        name: {
            elementType: 'input',
            elementConfig: {
                placeholder: "Nom",
            },
            value: '',
            label: '',
            validation: {
                required: true,
                minLength: 2
            },
            errorMessage: "Please enter your name",
            valid: false,
            touched: false
        },
        email: {
            elementType: 'input',
            elementConfig: {
                type: "email",
                placeholder: 'Entrez votre adresse email',
            },
            value: '',
            label: '',
            validation: {
                required: true,
                email: true
            },
            errorMessage: 'Please enter a valid email address',
            valid: false,
            touched: false
        },
        // eventRelationship: {
        //     elementType: "select",
        //     elementConfig: {
        //         options: [
        //             {value: "", display: "– Relation avec l'événement –"},
        //             {value: "PARTICIPANT", display: "Participant" },
        //             {value: "PROSPECT", display: "Participant Potentiel" },
        //             // {value: "VOLUNTEER", display: "Bénévole" },
        //             // {value: "SUPPORTER", display: "Supporteur" },
        //             // {value: "VENDOR", display: "Vendeur" },
        //             // {value: "MEDIA", display: "Media" },
        //             // {value: "COMMUNITY", display: "Communaute" },
        //             // {value: "OTHER", display: "Autre" },
        //         ]
        //     },
        //     value: "",
        //     label: "",
        //     validation: {
        //         required: true
        //     },
        //     errorMessage: "Please select a value",
        //     valid: false,
        //     touched: false
        // },
        // choiceOfEvent: {
        //     elementType: "select",
        //     elementConfig: {
        //         options: [
        //             {value:"", "display": "– Choix d'événement –"},
        //             {value:"TAR_MAIN", "display": "Événement principal du TAR"},
        //             {value:"TAR_PTIT", "display": "Le P’tit TAR"},
        //             {value:"TAR_MINI", "display": "Le Mini TAR - Tom's Trophy"},
        //             {value:"UNSURE", "display": "Je ne sais pas quel événement"},
        //             {value:"OTHER", "display": "Autre"},
        //         ]
        //     },
        //     value: "",
        //     label: "",
        //     validation: {
        //         required: true
        //     },
        //     errorMessage: "Please select an event",
        //     valid: false,
        //     touched: false
        // },
        // purpose: {
        //     elementType: "select",
        //     elementConfig: {
        //         options: [
        //             {value: "", display:"– Objet (facultatif) –"},
        //             {value: "RULES_AND_REGULATIONS", display:"Règles et Règlement"},
        //             {value: "REGISTRATION", display:"Inscription"},
        //             {value: "SELECT_A_RACE", display:"Sélectionner une Course"},
        //             {value: "MEDIA", display:"Média"},
        //             {value: "TRAINING_PROGRAM", display:"Programme de formation"},
        //             {value: "EVENT_SCHEDULE", display:"Horaire de l'événement"},
        //             {value: "ABOUT_CHAMONIX", display:"A propos de Chamonix"},
        //             {value: "HOW_TO_VOLUNTEER", display:"Comment faire du bénévolat"},
        //             {value: "JOIN_RUNNING_CLUB", display:"Rejoindre le club de running"},
        //             {value: "TRAVEL", display:"Voyage"},
        //             {value: "RACE_TIPS", display:"Conseils de course"},
        //             {value: "JUST_SAY_HI", display:"Dites juste salut"},
        //             {value: "EVENT_FEEDBACK", display:"Retour d'événement"},
        //             {value: "THANK_VOLUNTEERS", display:"Merci bénévoles"},
        //             {value: "SHARE_RACE_REPORT", display:"Partager le rapport de course"},
        //             {value: "CHAT_WITH_COACH", display:"Discutez avec un coach"},
        //             {value: "WEATHER", display:"Météo"},
        //             {value: "THINGS_TO_DO", display:"Choses à faire en ville"},
        //             {value: "OTHER_TOPICS", display:"Autre Sujet"},
        //         ]
        //     },
        //     value: "",
        //     label: "",
        //     validation: {
        //         required: false
        //     },
        //     errorMessage: "Please select an event",
        //     valid: true,
        //     touched: false
        // },
        message: {
            elementType: 'textarea',
            elementConfig: {
                rows: 6,
                placeholder: "Message"
            },
            value: "",
            label: "",
            validation: {
                required: true
            },
            errorMessage: "Please enter a message",
            valid: false,
            touched: false
        }

    }

    const [tarForm, setTarForm] = useState(initialTarForm);
    const [formIsValid, setFormIsValid] = useState(false);
    const [submitted, setSubitted] = useState(false);
    const [isTypingComplete, setIsTypingComplete] = useState(false);

    const handleTypingComplete = () => {
        setIsTypingComplete(true);
    };

    const inputChangeHandler = (event: any, inputId: keyof ITarForm) => {
        const updatedTarForm = {
            ...tarForm,
        };

        const updatedElement = {
            ...updatedTarForm[inputId],
        };

        updatedElement.value = event.target ? event.target.value : event;
        updatedElement.valid = checkIsValid(
            updatedElement.value,
            updatedElement.validation,
        );
        updatedElement.touched = true;

        updatedTarForm[inputId] = updatedElement;

        let formIsValid = true;
        Object.keys(updatedTarForm).forEach((key) => {
            formIsValid =
                updatedTarForm[key as keyof ITarForm].valid && formIsValid;
        });

        setTarForm(updatedTarForm);
        setFormIsValid(formIsValid);
    };

    const formElements: { config: IFormItem; id: string }[] = [];
    Object.keys(tarForm).forEach((key) => {
        formElements.push({
            config: tarForm[key as keyof ITarForm],
            id: key,
        });
    });

    const submitPressed = () => {
        setSubitted(true);
        if (formIsValid) {
            // Turn form to json string
            const formJson = {
                formType: "TAR",
                name: tarForm.name.value,
                email: tarForm.email.value,
                // eventRelationship: tarForm.eventRelationship.value,
                // choiceOfEvent: tarForm.choiceOfEvent.value,
                // purpose: tarForm.purpose.value,
                message:tarForm.message.value
            }
            props.sendMessage(JSON.stringify(formJson))
        }
    };

    return <>
        <TypingEffect text={"a Salut, comment allez-vous ? Je suis Skip, votre assistant événementiel. Je peux discuter avec vous du TAR, de la logistique ou même de l'état de vos préparatifs. Répondez simplement à quelques questions pour commencer à discuter."} onComplete={handleTypingComplete}></TypingEffect>
        {isTypingComplete?formElements.map((formElement) => (
        <Input
            elementType={formElement.config.elementType}
            label={formElement.config.label}
            elementConfig={formElement.config.elementConfig}
            key={formElement.id}
            invalid={!formElement.config.valid && submitted}
            shouldValidate={!!formElement.config.validation}
            errorMessage={formElement.config.errorMessage}
            touched={formElement.config.touched}
            handleChange={(event) =>
                inputChangeHandler(event, formElement.id as keyof ITarForm)
            }
            id={formElement.id}
        />)):null}
        {isTypingComplete?<Button
            id="login-button"
            type="button"
            value="Envoyer la requête"
            action={submitPressed}
        />:null}
        </>

}

export default TarForm;