import React from 'react';

import IChatDay from '../../classes/IChatDay';
import LoadingMessage from './LoadingMessage/LoadingMessage';
import Message from './Message/Message';

interface MessageProps {
  data: IChatDay[];
  showLoader: boolean;
}

const Messages: Function = (props: MessageProps) => {
  if (
    props.data.length &&
    props.data[0]?.day === 'Today' &&
    props.data[0]?.data.length === 0
  ) {
    return <LoadingMessage key="loading" />;
  }

  return props.data.map((dayData, index) => {
    if (dayData.data.length) {
      return (
        <div key={index} className="chat">
          {/* <span className="day-spacer">{dayData.day}</span> */}
          {dayData.data.map((messageData, messageIndex) => {
            return (
              <React.Fragment key={`${messageData.id}-${messageIndex}`}>
                <Message
                  key={`${messageData.id}-${messageIndex}`}
                  id={messageData.id}
                  from={messageData.messageFrom}
                  message={messageData.message}
                  is_liked={messageData.is_liked}
                  special={messageData.special}
                  timestamp={messageData.timestamp}
                />
              </React.Fragment>
            );
          })}
          {dayData.day === 'Today' && props.showLoader ? (
            <LoadingMessage key="loading" />
          ) : null}
        </div>
      );
    } else return null;
  });
};

export default Messages;
