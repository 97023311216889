import { connectTPActionTypes } from '../actions/actionTypes';

export interface TPConnectState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  success: boolean;
}

const initialState: TPConnectState = {
  loading: false,
  error: false,
  errorMessage: '',
  success: false,
};

type Action =
  | { type: typeof connectTPActionTypes.CONNECT_TP_START }
  | { type: typeof connectTPActionTypes.CONNECT_TP_SUCCESS }
  | {
      type: typeof connectTPActionTypes.CONNECT_TP_ERROR;
      errorMessage: string;
    };

const reducer = (state: TPConnectState = initialState, action: Action) => {
  switch (action.type) {
    case connectTPActionTypes.CONNECT_TP_START:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        error: false,
      };

    case connectTPActionTypes.CONNECT_TP_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        error: false,
        success: true,
      };

    default:
      return state;
  }
};

export default reducer;
