import { Dispatch } from 'redux';
import { connectTPActionTypes } from './actionTypes';
import axios from '../../axios-skip';

// For TrainingPeaks

const tpConnectStart = () => {
  return {
    type: connectTPActionTypes.CONNECT_TP_START,
  };
};

const tpConnectError = (error: string) => {
  return {
    type: connectTPActionTypes.CONNECT_TP_ERROR,
    errorMessage: error,
  };
};

interface TPConnectSuccessResponse {
  something: string;
}

const tpConnectSuccess = (data: TPConnectSuccessResponse) => {
  return {
    type: connectTPActionTypes.CONNECT_TP_SUCCESS,
  };
};

function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(null, args), wait);
  };
}

let isRequestInProgress = false;

const debouncedTpConnect = debounce(
  async (dispatch: Dispatch, code: string) => {
    if (isRequestInProgress) return;

    isRequestInProgress = true;
    dispatch(tpConnectStart());

    try {
      const response = await axios.post('/athlete/connect-tp', { code });
      dispatch(tpConnectSuccess(response.data));
    } catch (e) {
      dispatch(tpConnectError('There was an error connecting'));
    } finally {
      isRequestInProgress = false;
      window.close();
    }
  },
  300,
); // Adjust the debounce delay as needed

export const tpConnect = (code: string) => {
  return (dispatch: Dispatch) => {
    debouncedTpConnect(dispatch, code);
  };
};
