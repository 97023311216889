import { Dispatch } from 'redux';
import axios from '../../axios-skip';
import { Tab } from '../reducers/tabs';
import { tabsActionTypes } from './actionTypes';

const getTabsStart = () => {
  return {
    type: tabsActionTypes.GET_TABS_START,
  };
};

const getTabsSuccess = (data: Tab[]) => ({
  type: tabsActionTypes.GET_TABS_SUCCESS,
  tabs: data,
});

const getTabsFail = (error: any) => ({
  type: tabsActionTypes.GET_TABS_FAIL,
  error,
});

export const getTabs = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getTabsStart());
    try {
      const response = await axios.get('get-menu-tabs');
      dispatch(getTabsSuccess(response.data));
    } catch (error) {
      dispatch(getTabsFail(error));
    }
  };
};
