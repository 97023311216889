import { Dispatch } from 'redux';
import { connectStravaActionTypes } from './actionTypes';
import axios from '../../axios-skip';

// For Strava
const stravaConnectStart = () => {
  return {
    type: connectStravaActionTypes.CONNECT_STRAVA_START,
  };
};

const stravaConnectError = (error: string) => {
  return {
    type: connectStravaActionTypes.CONNECT_STRAVA_ERROR,
    errorMessage: error,
  };
};

interface StravaConnectSuccessResponse {
  something: string;
}

const stravaConnectSuccess = (data: StravaConnectSuccessResponse) => {
  return {
    type: connectStravaActionTypes.CONNECT_STRAVA_SUCCESS,
  };
};

function debounce(func: Function, wait: number) {
  let timeout: NodeJS.Timeout;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(null, args), wait);
  };
}

let isRequestInProgress = false;

const debouncedStravaConnect = debounce(
  async (dispatch: Dispatch, code: string) => {
    if (isRequestInProgress) return;

    isRequestInProgress = true;
    dispatch(stravaConnectStart());

    try {
      const response = await axios.post('/athlete/connect-strava', { code });
      dispatch(stravaConnectSuccess(response.data));
    } catch (e) {
      dispatch(stravaConnectError('There was an error connecting'));
    } finally {
      isRequestInProgress = false;
    }
  },
  300,
); // Adjust the debounce delay as needed

export const stravaConnect = (code: string) => {
  return (dispatch: Dispatch) => {
    debouncedStravaConnect(dispatch, code);
  };
};
