import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IAppState } from '../../classes/IAppState';
import { SuggestionType } from '../../classes/SuggestionType';
import * as actionCreators from '../../store/actions';

import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import TarForm from '../../components/Forms/TarForm';
import Messages from '../../components/Messages/Messages';
import ProfileDropdown2 from '../../components/ProfileDropdown/ProfileDropdown2';
import config from '../../config';

import { getTabs } from '../../store/actions';
import { resetUserState, updateAthlete } from '../../store/actions/users';
import { Tab } from '../../store/reducers/tabs';

import IChatDay from '../../classes/IChatDay';
import './chat.css';

interface ChatProps {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  chats: IChatDay[];
  getChats: () => void;
  newRefresh: () => void;
  firstName: string;
  lastName: string;
  logout: () => void;
  loggedIn: boolean;
}

const Chat = (props: ChatProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [messages, setMessages] = useState<IChatDay[]>([]);
  const [activetab, setActiveTab] = useState<Tab>();
  //
  const [tab1Messages, setTab1Messages] = useState<IChatDay[]>([]);
  const [tab1Suggestions, setTab1Suggestions] = useState<SuggestionType[]>([]);
  const [showPromptBox, setShowPromptBox] = useState<boolean>(true);
  //
  const [message, setMessage] = useState('');
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [canSendMessage, setCanSendMessage] = useState<boolean>(false);
  const [maxRetriesHit, setMaxRetriesHit] = useState<boolean>(false);
  //
  const [showProfileDropdown, setShowProfileDropdown] =
    useState<boolean>(false);

  let user = useSelector((state: IAppState) => state.users);
  const logged_in = user.loggedIn;
  const { tabs, loading } = useSelector((state: IAppState) => state.tabs);

  const queryParameters = new URLSearchParams(location.search);

  // Keeping Selected Tab in Focus
  const activeTabRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (activeTabRef.current) {
      activeTabRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [activetab]);

  // Redirect to the landing page if not logged in
  useEffect(() => {
    if (!logged_in) {
      navigate('/');
    }
  }, [logged_in, navigate]);

  // Fetch Tabs Only if no Tabs data in Redux Store
  useEffect(() => {
    if (tabs.length === 0) {
      dispatch(getTabs());
    }
  }, [dispatch, tabs]);

  // Fetch Chats
  const { getChats, chats } = props;
  useEffect(() => {
    getChats();
  }, [getChats]);

  // Redirect to the landing page if chats.length is 0
  useEffect(() => {
    if (chats.length === 0) {
      navigate('/');
    }
  }, [chats, navigate]);

  const tabEnum = queryParameters.get('tab');

  useEffect(() => {
    if (!loading && tabEnum && tabs.length > 0) {
      const tab = tabs.find((tab) => tab.enum === tabEnum);
      if (tab) {
        setActiveTab(tab);
      }
    } else {
      setActiveTab(tabs[0]);
    }
  }, [loading, tabEnum, tabs]);

  // console.log('all chats', chats);
  // console.log('tab1Messages', tab1Messages);
  // console.log('tab1Suggestions', tab1Suggestions);

  /* 
  Processing Chats from API:
  */

  useEffect(() => {
    const temp_messages: IChatDay[] = [];

    chats.forEach((chat) => {
      //

      const filtered_tab_messages = chat.data.filter(
        (message) => message.main_tab_enum === activetab?.enum,
      );

      //
      temp_messages.push({ day: chat.day, data: filtered_tab_messages });
    });

    setTab1Messages(temp_messages);

    setMessages(chats);
  }, [chats, setMessages, activetab]);

  const showTarForm =
    queryParameters.get('tar') &&
    messages.length === 1 &&
    messages[0].data.length === 0;

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  useEffect(() => {
    // document.querySelector('.chat-finished')?.scrollIntoView({
    //   block: 'end', // "start" | "center" | "end" | "nearest",
    //   behavior: 'auto', //"auto"  | "instant" | "smooth",
    // });

    document.querySelector('.chat-finished')?.scrollIntoView({});
  }, [tab1Messages, tab1Suggestions]);

  const handleRefresh = () => {
    navigate(0);
  };

  const handleMessageChange = (input: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(input.target.value);
  };

  const { newRefresh } = props;
  useEffect(() => {
    newRefresh();
  }, [newRefresh]);

  const logoutPressed = async () => {
    console.log('logout pressed');
    dispatch(resetUserState());
    localStorage.removeItem('accessToken');
    props.logout();
    navigate('/');
    navigate(0);
  };

  /* 
  Handling Receiving and Sending messages through Websocket 
  */

  const WS_URL =
    config.BACKEND_WS_URL + `ws?token=${localStorage.getItem('accessToken')}`;

  // Custom hook provide by react-use-websocket
  const {
    sendMessage,
    // sendJsonMessage,
    lastMessage,
    // lastJsonMessage,
    // readyState,
    // getWebSocket,
  } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log('opened');
      setCanSendMessage(true);
    },
    // handles reconnection
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 3,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    onReconnectStop: (numAttempts) => {
      setMaxRetriesHit(true);
    },
    // handle closing
    onClose: () => {
      console.log('closed');
      setShowLoadingMessage(false);
      setCanSendMessage(false);
    },
  });

  const addMessage = () => {
    if (message.length) {
      sendMessage(message);
      setShowLoadingMessage(true);
      setMessage('');
      setTab1Suggestions([]);
    }
  };

  const addMessageEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addMessage();
      setTab1Suggestions([]);
      event.preventDefault();
    }
  };

  const sendMessageFromSuggestions = (message: string, autoSend: boolean) => {
    if (autoSend) {
      sendMessage(message);
      setShowLoadingMessage(true);
      setMessage('');
      setTab1Suggestions([]);
    } else {
      setMessage(message);
    }
  };

  /*
  Dealing with Websocket Messages 
  */

  useEffect(() => {
    if (lastMessage !== null) {
      const messageData = JSON.parse(lastMessage.data);

      // Add handler for FORCE_REFRESH message type
      if (messageData.type === 'FORCE_REFRESH') {
        console.log('Force refresh WebSocket message received, reloading page');
        window.location.reload();
        return; // Early return to avoid processing the message further
      }

      // if inactive session for more than 6 hours
      if (messageData.special === 'START_NEW_SESSION') {
        navigate('/');
      }

      // if message is from SKIP
      if (messageData.from === 'SKIP') {
        setShowLoadingMessage(false);
      }

      // if message is a suggestion
      if (messageData.special === 'SUGGESTION') {
        if (messageData.show_prompt_box === false) {
          setShowPromptBox(false);
        } else if (messageData.show_prompt_box === true) {
          setShowPromptBox(true);
        }
        if (messageData.main_tab_enum === activetab?.enum) {
          setTab1Suggestions(messageData.options);
        }
      }

      // if message is a subscription change
      if (messageData.special === 'SUBS_CHANGED') {
        dispatch(
          updateAthlete([
            { field: 'subscription', value: messageData.subscription },
          ]),
        );
      }

      // Switching between tabs based on new message
      if (
        messageData.main_tab_enum !== activetab?.enum &&
        messageData.from === 'SKIP' &&
        messageData.special !== 'SUGGESTION'
      ) {
        const newActiveTab = tabs.find(
          (tab) => tab.enum === messageData.main_tab_enum,
        );
        if (newActiveTab) {
          setActiveTab(newActiveTab);
          // getChats();
        }
      }

      if (
        !tabs.some((tab) => tab.message === messageData.message) &&
        messageData.special !== 'SUGGESTION' &&
        messageData.special !== 'START_NEW_SESSION' &&
        messageData.special !== 'SUBS_CHANGED'
      ) {
        setTab1Messages((prev) => {
          const finalMessage = prev.slice(-1)[0]?.data?.slice(-1)[0] ?? null;

          // There is no previous message, so just set the new one
          if (!finalMessage) {
            return [
              ...prev.slice(0, prev.length - 1),
              {
                day: prev[prev.length - 1]?.day,
                data: [
                  ...(prev[prev.length - 1]?.data || []),
                  {
                    id: 'None',
                    timestamp: new Date(),
                    type: 'chat',
                    messageFrom: messageData.from,
                    message: messageData.message,
                    special: messageData.special,
                    options: messageData.options,
                  },
                ],
              },
            ];
          }
          // If the new message is a special message, remove the last message and add the new one
          if (
            (finalMessage.isStream &&
              !finalMessage.streamFinished &&
              messageData.type !== 'stream' &&
              messageData.special === 'TP_CONNECT_SUCCESS') ||
            messageData.special === 'ACTION_MESSAGE_RESPONSE'
          ) {
            return [
              ...prev.slice(0, prev.length - 1),
              {
                day: prev[prev.length - 1].day,
                data: [
                  ...prev[prev.length - 1].data.slice(
                    0,
                    prev[prev.length - 1].data.length - 1,
                  ),
                  {
                    id: 'None',
                    timestamp: new Date(),
                    type: 'chat',
                    messageFrom: messageData.from,
                    message: messageData.message,
                    special: messageData.special,
                    options: messageData.options,
                  },
                ],
              },
            ];
          }
          // The previous message is an unfinished stream and the new one is not, place the new one above it
          else if (
            finalMessage.isStream &&
            !finalMessage.streamFinished &&
            messageData.type !== 'stream'
          ) {
            return [
              ...prev.slice(0, prev.length - 1),
              {
                day: prev[prev.length - 1].day,
                data: [
                  ...prev[prev.length - 1].data.slice(
                    0,
                    prev[prev.length - 1].data.length - 1,
                  ),
                  {
                    id: 'None',
                    timestamp: new Date(),
                    type: 'chat',
                    messageFrom: messageData.from,
                    message: messageData.message,
                    special: messageData.special,
                    options: messageData.options,
                  },
                  {
                    ...prev[prev.length - 1].data.slice(-1)[0],
                  },
                ],
              },
            ];
          }
          // The stream is finished, mark it as finished.
          else if (
            messageData.type === 'stream' &&
            messageData.from === 'SKIP' &&
            finalMessage.messageFrom === 'SKIP' &&
            finalMessage.isStream &&
            messageData.message === null
          ) {
            return [
              ...prev.slice(0, prev.length - 1),
              {
                day: prev[prev.length - 1].day,
                data: [
                  ...prev[prev.length - 1].data.slice(
                    0,
                    prev[prev.length - 1].data.length - 1,
                  ),
                  {
                    ...prev[prev.length - 1].data.slice(-1)[0],
                    streamFinished: true,
                  },
                ],
              },
            ];
            // New message is a stream, continue adding it to the last message
          } else if (
            messageData.type === 'stream' &&
            messageData.from === 'SKIP' &&
            finalMessage.messageFrom === 'SKIP' &&
            finalMessage.isStream
          ) {
            return [
              ...prev.slice(0, prev.length - 1),
              {
                day: prev[prev.length - 1].day,
                data: [
                  ...prev[prev.length - 1].data.slice(
                    0,
                    prev[prev.length - 1].data.length - 1,
                  ),
                  {
                    id: 'None',
                    timestamp: new Date(),
                    type: 'chat',
                    messageFrom: 'SKIP',
                    message:
                      prev[prev.length - 1].data.slice(-1)[0].message +
                      messageData.message,
                    special: messageData.special,
                    options: messageData.options,
                    streamFinished: false,
                    isStream: true,
                  },
                ],
              },
            ];
          }
          // Just add as a new message underneath
          else {
            return [
              ...prev.slice(0, prev.length - 1),
              {
                day: prev[prev.length - 1].day,
                data: [
                  ...prev[prev.length - 1].data,
                  {
                    id: 'None',
                    timestamp: new Date(),
                    type: 'chat',
                    messageFrom: messageData.from,
                    message: messageData.message,
                    special: messageData.special,
                    options: messageData.options,
                    isStream: messageData.type === 'stream',
                  },
                ],
              },
            ];
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [lastMessage, setMessages, navigate]);

  const updateQueryParam = (key: string, value: string) => {
    queryParameters.set(key, value);
    navigate(`${location.pathname}?${queryParameters.toString()}`, {
      replace: true,
    });
  };

  // Fetching Suggestions for the active tab
  useEffect(() => {
    if (activetab) {
      sendMessage(activetab.message);
    }
  }, [activetab, sendMessage]);

  return (
    <div className="session-page">
      <div className="sidebar" />
      <div className="session-page-main">
        <div className="session-page-header">
          <div className="header-tabs-frame">
            <div className="header-tabs-frame-2">
              {tabs.map((tab, index) => (
                <button
                  key={index}
                  ref={tab.enum === activetab?.enum ? activeTabRef : null}
                  className={`header-tab ${
                    tab.enum === activetab?.enum ? 'header-tab-active' : ''
                  }`}
                  onClick={() => {
                    updateQueryParam('tab', tab.enum);
                    setActiveTab(tab);
                    setShowLoadingMessage(true);
                    sendMessage(tab.message);
                  }}
                  disabled={tab.enum === activetab?.enum ? true : false}
                  style={{
                    cursor:
                      tab.enum === activetab?.enum ? 'not-allowed' : 'pointer',
                  }}
                >
                  {tab.label}
                </button>
              ))}
            </div>
          </div>
          <div className="dropdown-wrapper2">
            <div className="profile-icon-frame">
              <img
                className="profile-icon4"
                alt=""
                src="/profileicon.svg"
                onClick={() => setShowProfileDropdown(!showProfileDropdown)}
              />
              {showProfileDropdown && (
                <ProfileDropdown2 logout={logoutPressed} user={user} />
              )}
            </div>
          </div>
        </div>
        <div className="chat-interface">
          {showTarForm && canSendMessage ? (
            <TarForm sendMessage={sendMessage} />
          ) : (
            <Messages data={tab1Messages} showLoader={showLoadingMessage} />
          )}
          <div className="chat-finished" id="chat-finished"></div>
        </div>
        <div className="prompt-frame">
          <button className="suggestions-frame">
            {tab1Suggestions.map((suggestion, index) => (
              <div
                key={index}
                onClick={() => {
                  sendMessageFromSuggestions(
                    suggestion.message,
                    suggestion.autoSend,
                  );
                }}
                className="suggestion-btn"
              >
                <div className="suggestion-text">{suggestion.label}</div>
              </div>
            ))}
          </button>
          {maxRetriesHit ? (
            <div className="reconnect-area" onClick={handleRefresh}>
              Reconnect
            </div>
          ) : (
            showPromptBox && (
              <div className="type-box1">
                <input
                  id="send-message-input"
                  type="text"
                  value={message}
                  onChange={handleMessageChange}
                  onKeyDown={addMessageEnter}
                  placeholder="Type or Use Above Suggested Text"
                  className="input-box1"
                />
                <button
                  className="send-icon-frame"
                  onClick={addMessage}
                  disabled={!canSendMessage}
                >
                  <div className="send-icon">
                    <img className="vector-icon" alt="" src="/sendicon.svg" />
                  </div>
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    loading: state.chats.loading,
    error: state.chats.error,
    errorMessage: state.chats.errorMessage,
    chats: state.chats.chats,
    firstName: state.users.firstName,
    lastName: state.users.lastName,
    loggedIn: state.users.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getChats: () => dispatch(actionCreators.getChats()),
    logout: () => dispatch(actionCreators.logout()),
    newRefresh: () => dispatch(actionCreators.getRefreshToken()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
