import React from 'react';

type ButtonProps = {
  type: 'submit' | 'button';
  value: string;
  action?: () => void;
  id: string;
  extraClases?: string;
};

const Button = (props: ButtonProps) => (
  <input
    id={props.id}
    type={props.type}
    value={props.value}
    onClick={props.action}
    className={'btn-submit ' + props.extraClases}
  />
);

export default Button;
