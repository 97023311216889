interface conf {
  BACKEND_URL: string;
  BACKEND_WS_URL: string;
  FRONTEND_URL: string;
  STRAVA_CALLBACK_URL: string;
  TP_CALLBACK_URL: string;
}

let localConfig: conf = {
  BACKEND_URL: 'http://localhost:8000/',
  BACKEND_WS_URL: 'ws://localhost:8000/',
  FRONTEND_URL: 'http://localhost:3000/',
  STRAVA_CALLBACK_URL:
    'https://www.strava.com/oauth/authorize?client_id=135875&redirect_uri=http://localhost:3000/stravaCallback&response_type=code&scope=read,activity:read_all',
  TP_CALLBACK_URL:
    'https://oauth.trainingpeaks.com/OAuth/Authorize?response_type=code&client_id=teamskip&scope=athlete%3Aprofile%20events%3Aread%20events%3Awrite%20metrics%3Aread%20workouts%3Aread%20workouts%3Adetails%20workouts%3Awod%20workouts%3Aplan&redirect_uri=http://localhost:3000/tpCallback',
};

let prodConfig: conf = {
  BACKEND_URL: 'https://api.teamskip.ai/',
  BACKEND_WS_URL: 'wss://api.teamskip.ai/',
  FRONTEND_URL: 'https://app.teamskip.ai/',
  STRAVA_CALLBACK_URL:
    'https://www.strava.com/oauth/authorize?client_id=135875&redirect_uri=https://app.teamskip.ai/stravaCallback&response_type=code&scope=read,activity:read_all',
  TP_CALLBACK_URL:
    'https://oauth.trainingpeaks.com/OAuth/Authorize?response_type=code&client_id=teamskip&scope=athlete%3Aprofile%20events%3Aread%20events%3Awrite%20metrics%3Aread%20workouts%3Aread%20workouts%3Adetails%20workouts%3Awod%20workouts%3Aplan&redirect_uri=https://app.teamskip.ai/tpCallback',
  // TP_CALLBACK_URL:
  //   'https://oauth.sandbox.trainingpeaks.com/OAuth/Authorize?response_type=code&client_id=teamskip&scope=athlete%3Aprofile%20events%3Aread%20events%3Awrite%20metrics%3Aread%20workouts%3Aread%20workouts%3Adetails%20workouts%3Awod%20workouts%3Aplan&redirect_uri=https://app.redpick.com/tpCallback',
};

let config = { ...localConfig };
if (process.env.NODE_ENV === 'production') {
  config = { ...prodConfig };
}

export default config;
