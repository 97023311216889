import axios from 'axios';
import config from './config';

const BASE_URL = config.BACKEND_URL;

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

instance.interceptors.request.use(async (config) => {
  if (localStorage.getItem('accessToken')) {
    config.headers['authorization'] = `Bearer ${localStorage.getItem(
      'accessToken',
    )}`;
    config.headers['Access-Control-Allow-Credentials'] = 'true';
    config.headers.Accept = 'application/json';
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalReq = error.config;
    // If certain type of response, try refresh end point and resend the request
    if (error.response.status === 401 && !error.config.__isRetryRequest) {
      let res = await axios.post(
        `${BASE_URL}refresh-jwt`,
        {},
        { withCredentials: true, headers: { Connection: 'close' } },
      );

      if (res.status === 200) {
        localStorage.setItem('accessToken', res.data.jwt);
        localStorage.setItem('email', res.data.email);
        // Retry the old request
        return instance(originalReq);
      } else {
        // Refresh failed
        console.log('refresh failed!');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('email');
        localStorage.removeItem('firstName');
        localStorage.removeItem('lastName');
      }
    }
    throw error;
  },
);

export default instance;
