import {
  CSSProperties,
  RefObject,
  FunctionComponent,
  ReactNode,
  useMemo,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';

import { createPortal } from 'react-dom';
import './PortalPopup.css';

type PopupProps = {
  overlayColor?: string;
  placement?:
    | 'Centered'
    | 'Top left'
    | 'Top center'
    | 'Top right'
    | 'Bottom left'
    | 'Bottom center'
    | 'Bottom right';
  onOutsideClick?: () => void;
  zIndex?: number;
  children: ReactNode;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  relativeLayerRef?: RefObject<HTMLElement>;
};

const PortalPopup: FunctionComponent<PopupProps> = ({
  children,
  overlayColor,
  placement = 'Centered',
  onOutsideClick,
  zIndex = 100,
  left = 0,
  right = 0,
  top = 0,
  bottom = 0,
  relativeLayerRef,
}) => {
  const relContainerRef = useRef<HTMLDivElement>(null);
  const [relativeStyle, setRelativeStyle] = useState<CSSProperties>({
    opacity: 0,
  });
  const popupStyle = useMemo(() => {
    const style: CSSProperties = {};
    style.zIndex = zIndex;

    if (overlayColor) {
      style.backgroundColor = overlayColor;
    }
    if (!relativeLayerRef?.current) {
      switch (placement) {
        case 'Centered':
          style.alignItems = 'center';
          style.justifyContent = 'center';
          break;
        case 'Top left':
          style.alignItems = 'flex-start';
          break;
        case 'Top center':
          style.alignItems = 'center';
          break;
        case 'Top right':
          style.alignItems = 'flex-end';
          break;
        case 'Bottom left':
          style.alignItems = 'flex-start';
          style.justifyContent = 'flex-end';
          break;
        case 'Bottom center':
          style.alignItems = 'center';
          style.justifyContent = 'flex-end';
          break;
        case 'Bottom right':
          style.alignItems = 'flex-end';
          style.justifyContent = 'flex-end';
          break;
      }
    }
    style.opacity = 1;
    return style;
  }, [placement, overlayColor, zIndex, relativeLayerRef?.current]);

  const setPosition = useCallback(() => {
    const relativeItem = relativeLayerRef?.current?.getBoundingClientRect();
    const containerItem = relContainerRef?.current?.getBoundingClientRect();
    const style: CSSProperties = { opacity: 1 };
    if (relativeItem && containerItem) {
      const {
        x: relativeX,
        y: relativeY,
        width: relativeW,
        height: relativeH,
      } = relativeItem;
      const { width: containerW, height: containerH } = containerItem;
      style.position = 'absolute';
      switch (placement) {
        case 'Top left':
          style.top = relativeY - containerH - top;
          style.left = relativeX + left;
          break;
        case 'Top right':
          style.top = relativeY - containerH - top;
          style.left = relativeX + relativeW - containerW - right;
          break;
        case 'Bottom left':
          style.top = relativeY + relativeH + bottom;
          style.left = relativeX + left;
          break;
        case 'Bottom right':
          style.top = relativeY + relativeH + bottom;
          style.left = relativeX + relativeW - containerW - right;
          break;
      }

      setRelativeStyle(style);
    } else {
      style.maxWidth = '90%';
      style.maxHeight = '90%';
      setRelativeStyle(style);
    }
  }, [left, right, top, bottom, placement, relativeLayerRef, relContainerRef]);

  useEffect(() => {
    setPosition();

    window.addEventListener('resize', setPosition);
    window.addEventListener('scroll', setPosition, true);

    return () => {
      window.removeEventListener('resize', setPosition);
      window.removeEventListener('scroll', setPosition, true);
    };
  }, [setPosition]);

  const onOverlayClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        onOutsideClick &&
        (e.target as HTMLElement).classList.contains('portalPopupOverlay')
      ) {
        onOutsideClick();
      }
      e.stopPropagation();
    },
    [onOutsideClick],
  );

  return (
    <Portal>
      <div
        className="portalPopupOverlay"
        style={popupStyle}
        onClick={onOverlayClick}
      >
        <div
          ref={relContainerRef}
          style={{ ...relativeStyle, display: 'flex' }}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
};

type PortalProps = {
  children: ReactNode;
  containerId?: string;
};

export const Portal: FunctionComponent<PortalProps> = ({
  children,
  containerId = 'portals',
}) => {
  let portalsDiv = document.getElementById(containerId);
  if (!portalsDiv) {
    portalsDiv = document.createElement('div');
    portalsDiv.setAttribute('id', containerId);
    document.body.appendChild(portalsDiv);
  }

  return createPortal(children, portalsDiv);
};

export default PortalPopup;
