import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import chatsReducer from './store/reducers/chats';
import stravaConnectReducer from './store/reducers/stravaConnect';
import tabsReducer from './store/reducers/tabs';
import tpConnectReducer from './store/reducers/tpConnect';
import usersReducer from './store/reducers/users';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
//
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import * as Sentry from '@sentry/react';

import ReactGA from 'react-ga4';

ReactGA.initialize('G-5YB4SV8VP4');

Sentry.init({
  dsn: 'https://d9543c654376cd747a322cf002d4706b@o4506858171269120.ingest.us.sentry.io/4506858222845952',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app\.teamskip\.ai/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// Add a type declaration for Redux DevTools
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Combine reducers
const rootReducer = combineReducers({
  users: usersReducer,
  chats: chatsReducer,
  tabs: tabsReducer,
  stravaConnect: stravaConnectReducer,
  tpConnect: tpConnectReducer,
});

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store with middleware
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(persistedReducer, compose(applyMiddleware(thunk)));

// interface Action {
//   type: string;
//   payload?: any;
// }

// interface Store {
//   getState: () => any;
//   dispatch: (action: Action) => void;
// }

// type Next = (action: Action) => any;

// const loggerMiddleware = (store: Store) => (next: Next) => (action: Action) => {
//   console.log('Dispatching:', action);
//   console.log('State before:', store.getState());
//   let result = next(action);
//   console.log('State after:', store.getState());
//   return result;
// };

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const persistor = persistStore(store);

const root = createRoot(
  document.getElementById('root') as Element, // Use a type assertion to ensure it's not null
);

// persistor.subscribe(() => {
//   console.log('Persistor state:', store.getState());
// });

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
