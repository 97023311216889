import ConnectToStravaButton from '../Strava/ConnectToStravaButton';
import { IMessageType } from '../../classes/IMessageType';

interface StravaConnectProps {
  url: string;
}

const StravaConnect = (props: StravaConnectProps) => {
  return (
    <>
      <ConnectToStravaButton url={props.url} />
    </>
  );
};

export default StravaConnect;
