import ConnectToTPButton from '../TP/ConnectToTPButton';
import { IMessageType } from '../../classes/IMessageType';

interface TPConnectProps {
  url: string;
}

const TPConnect = (props: TPConnectProps) => (
  <>
    <ConnectToTPButton url={props.url} />
  </>
);

export default TPConnect;
