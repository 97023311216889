import { connect } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { IAppState } from './classes/IAppState';
import Chat from './containers/Chat/Chat';
import LandingPage from './containers/Landing/LandingPage';
import StravaCallback from './containers/StravaCallback/StravaCallback';
import SubscriptionSuccess from './containers/SubscriptionSuccess/SubscriptionSuccess';
import TpCallback from './containers/TpCallback/TpCallback';

interface AppProps {
  loggedIn: boolean;
}

function App(props: AppProps) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/subscriptionSuccess" element={<SubscriptionSuccess />} />
        <Route path="/stravaCallback" element={<StravaCallback />} />
        <Route path="/tpCallback" element={<TpCallback />} />
      </Routes>
    </Router>
  );
}

const mapStateToProps = (state: IAppState) => {
  return {
    loggedIn: state.users.loggedIn,
  };
};
export default connect(mapStateToProps)(App);
