import React from 'react';

type InputProps = {
  elementType: 'input' | 'textarea' | 'select' | 'date' | 'rating';
  value?: string | Date | number;
  label: string;
  elementConfig: {
    type?: string;
    placeholder?: string;
    options?: { value: string; display: string }[];
  };
  handleChange?: (input: any) => void;
  onBlur?: (val?: any) => void;
  shouldValidate: boolean;
  invalid: boolean;
  touched: boolean;
  errorMessage?: string;
  id?: string;
};

const Input = (props: InputProps) => {
  let error = false;
  if (props.shouldValidate && props.invalid) {
    // Show as error
    error = true;
  }

  let inputElement;
  switch (props.elementType) {
    case 'input':
      inputElement = (
        <input
          {...props.elementConfig}
          value={props.value as string}
          onChange={props.handleChange}
          className={
            'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline' +
            (error ? ' border-red-500' : '')
          }
          style={{"paddingTop":"0.5rem","paddingBottom":"0.5rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","marginTop":"0.25rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%","lineHeight":1.25,"color":"#374151","appearance":"none","boxShadow":"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"}}
          onBlur={props.onBlur}
          id={props.id}
        />
      );
      break;
    case 'select':
      inputElement = (
        <select
          id={props.id}
          value={props.value as string}
          onChange={props.handleChange}
          className={
            'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline' +
            (error ? ' border-red-500' : '')
          }
          style={{"paddingTop":"0.5rem","paddingBottom":"0.5rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","marginTop":"0.25rem","borderRadius":"0.25rem","borderWidth":"1px","width":"100%","lineHeight":1.25,"color":"#374151","appearance":"none","boxShadow":"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"}}
        >
          {props.elementConfig.options?.map((option) => (
            <option key={option.value} value={option.value}>
              {option.display}
            </option>
          ))}
        </select>
      );
      break;

    case 'textarea':
      inputElement = (
        <textarea
          id={props.id}
          className="w-full px-3 py-2 text-gray-700 border rounded-lg focus: outline-none"
          {...props.elementConfig}
          value={props.value as string}
          onChange={props.handleChange}
          style={{"paddingTop":"0.5rem","paddingBottom":"0.5rem","paddingLeft":"0.75rem","paddingRight":"0.75rem","borderRadius":"0.5rem","borderWidth":"1px","outlineStyle":"none","width":"100%","color":"#374151","marginTop":"0.25rem"}}
        />
      );

      break;
  }

  return (
    <div className="flex flex-col pt-4">
      <label className="text-lg">{props.label}</label>
      {inputElement}
      {error ? <div className="form-error">{props.errorMessage}</div> : null}
    </div>
  );
};

export default Input;
