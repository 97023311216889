import { IAppState } from '../../classes/IAppState';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as actionCreators from '../../store/actions';
import { connect } from 'react-redux';
import { useEffect } from 'react';

interface StravaCallbackProps {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  success: boolean;
  stravaConnect: (code: string) => void;
}

const StravaCallback = (props: StravaCallbackProps) => {
  const queryParameters = new URLSearchParams(window.location.search);

  // const state = queryParameters.get("state")
  const code = queryParameters.get('code');
  console.log(code);

  if (code && code?.length) {
    // Code exists, send to our backend to try and connect strava
    props.stravaConnect(code);
    // window.close();
  }

  const { success } = props;

  useEffect(() => {
    if (success) {
      window.close();
    }
  }, [success]);

  return (
    <h3>
      Strava connected, you may close this window/tab and return to TeamSkip
    </h3>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    loading: state.stravaConnect.loading,
    error: state.stravaConnect.error,
    errorMessage: state.stravaConnect.errorMessage,
    success: state.stravaConnect.success,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    stravaConnect: (code: string) =>
      dispatch(actionCreators.stravaConnect(code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StravaCallback);
