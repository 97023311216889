import { tabsActionTypes } from '../actions/actionTypes';

export interface Tab {
  label: string;
  message: string;
  enum: string;
  description: string;
}

export interface TabState {
  tabs: Tab[];
  loading: boolean;
  error: string | null;
}

const initialState: TabState = {
  tabs: [],
  loading: false,
  error: null,
};

const tabsReducer = (state = initialState, action: any): TabState => {
  switch (action.type) {
    case tabsActionTypes.GET_TABS_START:
      return {
        ...state,
        loading: true,
      };
    case tabsActionTypes.GET_TABS_SUCCESS:
      return {
        ...state,
        loading: false,
        tabs: action.tabs,
      };
    case tabsActionTypes.GET_TABS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default tabsReducer;
