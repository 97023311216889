import { useState } from 'react';
import { FunctionComponent } from 'react';
import { IAppState } from '../../classes/IAppState';
import axios from 'axios';
import config from '../../config';
import './PricingPopup.css';

//
const BASE_URL = config.BACKEND_URL;
const STRIPE_PUBLISHABLE_KEY =
  'pk_live_51QV7HSKNIb81QzFbC8v4IMogfgM7mSfoBtFJ9PlEHhKpR0w6JdSghicVc4Whczwy9ttqD70PZ7ugg4LdJUV0ZfJg00WRkFTh5p';
const PRICE_ID = 'price_1QuAoRKNIb81QzFborJVfl0r'; // Replace with the new plan's price ID

declare global {
  interface Window {
    Stripe: any;
  }
}

// Loading Stripe script not through the Stripe SDK so that it does not make Analytics requests
const loadStripeScript = () => {
  return new Promise<void>((resolve, reject) => {
    if (document.querySelector('script[src="https://js.stripe.com/v3/"]')) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/';
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Failed to load Stripe.js'));
    document.body.appendChild(script);
  });
};

const createCheckoutSession = async () => {
  await loadStripeScript();

  const stripe = window.Stripe(STRIPE_PUBLISHABLE_KEY);
  const response = await fetch(`${BASE_URL}create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
    body: JSON.stringify({ price_id: PRICE_ID }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  if (data.checkout_url) {
    if (stripe) {
      window.open(data.checkout_url, '_blank'); // Open in a new tab
    }
  } else {
    console.error('Failed to create checkout session:', data);
  }
};

//

export type PricingPopupType = {
  className?: string;
  onClose?: () => void;
  user: IAppState['users'];
};

const PricingPopup: FunctionComponent<PricingPopupType> = ({
  className = '',
  onClose,
  user,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  const [placeholder, setPlaceholder] = useState('Enter Code');

  let subscription_status = user.subscription.status;
  let subscription_plan = user.subscription.plan;
  let subscriptionID = user.subscription.id;
  let current_period_start_date = new Date(
    user.subscription.current_period_start_date || 0,
  );
  let current_period_end_date = new Date(
    user.subscription.current_period_end_date || 0,
  );
  let utilized_trial = user.utilized_trial;

  //
  const handleUpgrade = async () => {
    setIsLoading(true);
    try {
      await createCheckoutSession();
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setIsLoading(false);
    }
  };

  //
  const cancelSubscription = async () => {
    if (
      !window.confirm(
        'Are you sure you want to cancel your subscription? This action cannot be undone. You will continue to enjoy the benefits of your current plan until the end of the current billing cycle.',
      )
    ) {
      return; // Abort cancellation if the user clicks "Cancel"
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}cancel-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + user.accessToken,
        },
        body: JSON.stringify({
          subscription_id: subscriptionID,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Subscription canceled:', data);
    } catch (error) {
      console.error('Error canceling subscription:', error);
    } finally {
      setIsLoading(false);
    }
  };

  //
  const handleUpgradeWithCode = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${BASE_URL}verify-invitation-code`,
        {
          code: inviteCode,
        },
        {
          headers: {
            Authorization: 'Bearer ' + user.accessToken,
          },
        },
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setInviteCode('');
          setPlaceholder(error.response.data.detail);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`pricing-popup ${className}`}>
      <div className="popup-header">
        <div className="upgrade-plan-box">
          <b className="text-bold-20">Upgrade Plan</b>
          {user.subscription.active && (
            <button className="close-popup-btn" onClick={onClose}>
              <img className="cross-icon" alt="" src="/crossicon.svg" />
            </button>
          )}
        </div>
      </div>
      <div className="pricing-box">
        <div className="pricing-text-div">
          <div className="text-regular-18">pricing</div>
          {user.subscription.active ? (
            <div className="affordable-pricing-plans">
              Affordable pricing plans
            </div>
          ) : (
            <div className="affordable-pricing-plans">
              Please choose a plan to continue.
            </div>
          )}
        </div>
        <div className="pricing-plans">
          <div
            className="standard-plan"
            style={{ height: user.subscription.active ? 'auto' : '320px' }}
          >
            <div className="plan-icon-div">
              <div className="plan-icon">
                <div className="ellipse-group">
                  <div className="frame-item" />
                  <div className="frame-inner" />
                </div>
              </div>
              {subscription_plan === 'PILOT' ? (
                <div className="price">PILOT</div>
              ) : subscription_plan === 'INFLUENCER' ? (
                <div className="price">INFLUENCER</div>
              ) : (
                <div className="price">Standard Plan</div>
              )}
            </div>
            {subscription_plan === 'FREE' ||
            subscription_plan === 'INFLUENCER' ||
            subscription_plan === 'PILOT' ? (
              <>
                {subscription_status ? (
                  <>
                    {subscription_plan === 'INFLUENCER' ||
                    subscription_plan === 'PILOT' ? (
                      <>
                        <div className="paragraph">
                          <span>
                            Your current free subscription is active until{' '}
                            {current_period_end_date.toLocaleDateString()}.{' '}
                          </span>
                        </div>
                        <div>
                          <span>
                            You can upgrade your plan anytime to standard plan
                            at
                          </span>
                        </div>
                        <div className="price-div">
                          <div className="price">$4</div>
                          <div className="month">/month</div>
                        </div>
                        <button
                          className="upgrade-plan-btn"
                          disabled={isLoading}
                          onClick={handleUpgrade}
                        >
                          <div className="subscrptiontext">
                            {isLoading ? 'Loading...' : 'Upgrade Plan'}
                          </div>
                        </button>
                      </>
                    ) : (
                      <>
                        <div className="paragraph">
                          <span>
                            Your current subscription is active until{' '}
                            {current_period_end_date.toLocaleDateString()}.{' '}
                          </span>
                        </div>
                        <div>
                          <span>
                            You can re-subscribe to standard plan after the
                            current subscription ends for
                          </span>
                        </div>
                        <div className="price-div">
                          <div className="price">$4</div>
                          <div className="month">/month</div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="paragraph">
                      <span>{`Enjoy a free `}</span>
                      <span className="week-trial">2-week trial</span>
                      <span>{`, then continue for just `}</span>
                    </div>
                    <div className="price-div">
                      <div className="price">$4</div>
                      <div className="month">/month</div>
                    </div>
                    <div className="paragraph">
                      to access personalized AI coaching, workout tracking, and
                      performance insights.
                    </div>
                    <button
                      className="upgrade-plan-btn"
                      disabled={isLoading}
                      onClick={handleUpgrade}
                    >
                      <div className="subscrptiontext">
                        {isLoading ? 'Loading...' : 'Upgrade Plan'}
                      </div>
                    </button>
                  </>
                )}
              </>
            ) : (
              <>
                {subscription_status === 'trialing' ? (
                  <div className="paragraph">
                    * You are currently on a free trial. You will be
                    automatically charged <b>$4 / month</b> on{' '}
                    {current_period_end_date.toLocaleDateString()} unless you
                    cancel your subscription.
                  </div>
                ) : (
                  <>
                    <div className="paragraph">
                      Current Billing Cycle: &nbsp;{' '}
                      {current_period_start_date.toLocaleDateString()} -{' '}
                      {current_period_end_date.toLocaleDateString()}
                    </div>
                    <div className="paragraph">
                      * You will be automatically charged <b> $4 per month </b>{' '}
                      for the next billing cycle unless you cancel your
                      subscription.
                    </div>
                  </>
                )}
                <button
                  className="upgrade-plan-btn"
                  onClick={cancelSubscription}
                  disabled={isLoading}
                >
                  <div className="subscrptiontext">
                    {isLoading ? 'Canceling...' : 'Cancel Subscription'}
                  </div>
                </button>
              </>
            )}
          </div>
          {!utilized_trial && (
            <div
              className="standard-plan"
              style={{ height: user.subscription.active ? 'auto' : '320px' }}
            >
              <div className="plan-icon-div">
                <div className="plan-icon">
                  <div className="ellipse-group">
                    <div className="frame-item" />
                    <div className="frame-inner" />
                  </div>
                </div>
                <div className="price">Invitation Only</div>
              </div>
              <div className="paragraph2">
                <p className="exclusive-invite-only-access">
                  <span className="exclusive-invite-only-access1">{`Exclusive invite-only access, `}</span>
                  <span className="valid-for-3">{`valid for certain time `}</span>
                  <span>with premium AI coaching and advanced analytics.</span>
                </p>
              </div>
              <div className="invitation-btns-div">
                <input
                  className="code-input"
                  type="text"
                  placeholder={placeholder}
                  value={inviteCode}
                  onChange={(e) => setInviteCode(e.target.value)}
                />
                <button className="upgrade-btn" onClick={handleUpgradeWithCode}>
                  <div className="subscrptiontext">
                    {isLoading ? 'Loading ...' : 'Upgrade'}
                  </div>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricingPopup;
