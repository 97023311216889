import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import ProfileDropdown from '../../components/ProfileDropdown/ProfileDropdown';
import * as actionCreators from '../../store/actions';

import { IAppState } from '../../classes/IAppState';

import useWebSocket from 'react-use-websocket';
import axios from '../../axios-skip';
import IChatDay from '../../classes/IChatDay';
import AccordionCard from '../../components/Accordion/AccordionCard';
import config from '../../config';
import { getTabs } from '../../store/actions';
import {
  login,
  resetUserState,
  updateAthlete,
} from '../../store/actions/users';
import { Tab } from '../../store/reducers/tabs';
import './landing-page.css';

interface ChatProps {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  chats: IChatDay[];
  getChats: () => void;
  newRefresh: () => void;
  firstName: string;
  lastName: string;
  logout: () => void;
  loggedIn: boolean;
}

const LandingPage = (props: ChatProps) => {
  const navigate = useNavigate();
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  //
  const [user_message, setUserMessage] = useState<string>('');
  const [backend_response, setBackendResponse] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isValidCode, setIsValidCode] = useState<boolean>(false);
  const [verifyingStage, setVerifyingStage] = useState<boolean>(false);
  const [placeholder, setPlaceholder] = useState<string>(
    'teamskip.ai@gmail.com',
  );
  const [processing_tabs, setProcessingTabs] = useState<boolean>(false);
  const [canSendMessage, setCanSendMessage] = useState<boolean>(false);
  const [maxRetriesHit, setMaxRetriesHit] = useState<boolean>(false);
  const [faqs_list, setFaqsList] = useState<any[]>([]);

  let user = useSelector((state: IAppState) => state.users);
  let logged_in = user.loggedIn;
  const { tabs } = useSelector((state: IAppState) => state.tabs);

  // Fetch Tabs Only if no Tabs data in Redux Store
  useEffect(() => {
    if (tabs.length === 0) {
      dispatch(getTabs());
    }
  }, [dispatch, tabs]);

  // Fetch Chats:
  const { getChats } = props;
  useEffect(() => {
    getChats();
  }, [getChats]);

  // API Call to Process Tabs
  useEffect(() => {
    if (logged_in) {
      setProcessingTabs(true);
      axios
        .post('athlete/process-tabs-chats')
        .then(() => {
          dispatch(updateAthlete([{ field: 'active', value: true }]));
          setProcessingTabs(false);
        })
        .catch((error) => {
          console.error('Error processing tabs and chats:', error);
          setProcessingTabs(false);
        });
    }
  }, [dispatch, logged_in]);

  // Get FAQs if logged in and training peaks athlete id is not present
  useEffect(() => {
    if (logged_in && !user.trainingpeaks_athlete_id) {
      axios
        .get('get-faqs')
        .then((result) => {
          setFaqsList(result.data);
        })
        .catch((error) => {
          console.error('Error processing tabs and chats:', error);
        });
    }
  }, [logged_in, user.trainingpeaks_athlete_id]);

  //
  const handleRefresh = () => {
    navigate(0);
  };

  const logoutPressed = async () => {
    console.log('logout pressed');
    await dispatch(resetUserState());
    props.logout();
    navigate(0);
  };

  /* 
  Handling Receiving and Sending messages through Websocket 
  */

  const WS_URL =
    config.BACKEND_WS_URL + `ws?token=${localStorage.getItem('accessToken')}`;

  // Custom hook provide by react-use-websocket
  const {
    sendMessage,
    // sendJsonMessage,
    lastMessage,
    // lastJsonMessage,
    // readyState,
    // getWebSocket,
  } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log('opened');
      setCanSendMessage(true);
    },
    // handles reconnection
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 3,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    onReconnectStop: (numAttempts) => {
      setMaxRetriesHit(true);
    },
    // handle closing
    onClose: () => {
      console.log('closed');
      setCanSendMessage(false);
    },
  });

  /*
  Dealing with Websocket Messages 
  */

  useEffect(() => {
    if (lastMessage !== null) {
      const messageData = JSON.parse(lastMessage.data);

      // if inactive session for more than 6 hours
      if (messageData.special === 'START_NEW_SESSION') {
        dispatch(updateAthlete([{ field: 'active', value: false }]));
      }

      // if message is from SKIP
      if (messageData.from === 'SKIP') {
        setBackendResponse(messageData.message);
      } else {
        setBackendResponse('Processing... Please wait');
      }

      // If login code sent
      if (messageData.special === 'login_code_sent') {
        setPlaceholder('Enter 6-digit login code');
        setVerifyingStage(true);
      }

      // If the message contains a JWT then its a login request, need to set the token and refresh
      // Handling JWT received from login attempt
      if (messageData.jwt && messageData.jwt.length) {
        dispatch(
          login(
            messageData.jwt,
            messageData.first_name,
            messageData.last_name,
            messageData.email,
            messageData.trainingpeaks_athlete_id,
            messageData.subscription,
            messageData.utilized_trial,
          ),
        );
      }

      if (messageData.special === 'TP_CONNECT_SUCCESS') {
        dispatch(
          updateAthlete([
            {
              field: 'trainingpeaks_athlete_id',
              value: messageData.trainingpeaks_athlete_id,
            },
            {
              field: 'analysingTpData',
              value: true,
            },
            { field: 'first_name', value: messageData.first_name },
            { field: 'last_name', value: messageData.last_name },
          ]),
        );
      }

      if (messageData.special === 'TP_DATA_ANALYSED') {
        dispatch(updateAthlete([{ field: 'analysingTpData', value: false }]));
      }

      if (messageData.special === 'SUBS_CHANGED') {
        dispatch(
          updateAthlete([
            { field: 'subscription', value: messageData.subscription },
            { field: 'utilized_trial', value: messageData.utilized_trial },
          ]),
        );
        navigate(0);
      }

      setUserMessage('');
    }
    // eslint-disable-next-line
  }, [lastMessage, navigate]);

  //

  const addMessage = () => {
    if (user_message.length) {
      sendMessage(user_message);
      setUserMessage('');
      setIsValidEmail(false);
      setIsValidCode(false);
    }
  };

  const addMessageEnterEmail = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      if (isValidEmail) {
        addMessage();
        setUserMessage('');
        setIsValidEmail(false);
      } else {
        setBackendResponse('Please enter a valid email address');
        setUserMessage('');
      }
      event.preventDefault();
    }
  };

  const addMessageEnterCode = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      if (isValidCode) {
        addMessage();
        setUserMessage('');
        setIsValidCode(false);
      } else {
        setBackendResponse('Please enter a 6-digit numerical code');
        setUserMessage('');
      }
      event.preventDefault();
    }
  };

  //

  const handleTabClick = (tab: Tab) => {
    navigate(`/chat?tab=${tab.enum}`);
  };

  const validateEmail = (email: string) => {
    if (email.includes('guest-teamskip')) {
      return true;
    }
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleInputChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUserMessage(event.target.value);
    setIsValidEmail(validateEmail(event.target.value));
  };

  const validateCode = (code: string) => {
    const re = /^\d{6}$/;
    return re.test(code);
  };

  const handleInputChangeCode = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUserMessage(event.target.value);
    setIsValidCode(validateCode(event.target.value));
  };
  //

  const handleTpClick = () => {
    window.open(config.TP_CALLBACK_URL, '_blank', 'noreferrer');
  };

  const handleTpRegisterClick = () => {
    window.open(
      'https://home.trainingpeaks.com/signup',
      '_blank',
      'noreferrer',
    );
  };

  // If logged in and training peaks connected
  if (logged_in && user.trainingpeaks_athlete_id) {
    return (
      <div className="landing-page">
        <div className="header">
          <img className="skip-logo-icon" alt="" src="/teamskiplogo.svg" />
          <div className="dropdown-wrapper">
            <img className="profile-icon" alt="" src="/profileicon.svg" />
            <ProfileDropdown logout={logoutPressed} user={user} />
          </div>
        </div>
        {user.analysingTpData || processing_tabs ? (
          <div className="main-loading-frame">
            <div className="loading-frame">
              <div className="loading-animation">
                <img src="/loading-animation-3.gif" alt="Loading..." />
              </div>
              <div className="loading-text">{backend_response}</div>
            </div>
            <div className="tabs-blurred-frame">
              {tabs.map((tab, index) => (
                <div key={index} className="tab-blurred">
                  <img
                    className="tab-blurred-icon"
                    alt=""
                    src="/tabblurredicon.svg"
                  />
                  <div className="tab-text-frame2">
                    <div className="loading-text">{tab.label}</div>
                    <div className="tab-description">{tab.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="main-loading-ready-frame">
            <div className="tabs-ready-frame">
              {tabs.map((tab, index) => (
                <div
                  key={index}
                  className="tab-ready"
                  onClick={() => {
                    handleTabClick(tab);
                  }}
                >
                  <img className="icon" alt="" src="/tab-icon.svg" />
                  <div className="tab-text-frame3">
                    <div className="text-32">{tab.label}</div>
                    <div className="text-41">{tab.description}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  } else if (logged_in) {
    // If logged in and training peaks not connected
    return (
      <div className="landing-page">
        <div className="header">
          <img className="skip-logo-icon" alt="" src="/teamskiplogo.svg" />
          <div className="dropdown-wrapper">
            <img className="profile-icon" alt="" src="/profileicon.svg" />
            <ProfileDropdown logout={logoutPressed} user={user} />
          </div>
        </div>
        <div className="tp-connect-main-frame">
          <div className="tp-buttons-frame">
            <div className="tp-logo-frame">
              <img className="tp-logo-icon" alt="" src="/tplogo@2x.png" />
            </div>
            <div className="buttons-frame">
              <div className="connect-btn" onClick={handleTpClick}>
                <div className="btn-text">Connect with TrainingPeaks</div>
                <img className="connect-icon" alt="" src="/connect-icon.svg" />
              </div>
              <div className="register-btn-frame">
                <div className="text-a">{`Don’t have Account? `}</div>
                <div
                  className="register-now-btn"
                  onClick={handleTpRegisterClick}
                >
                  Register Now
                </div>
              </div>
              <div className="tp-connect-response">{backend_response}</div>
            </div>
          </div>
          <div className="info-accordions">
            {faqs_list.map((faq, key) => (
              <AccordionCard
                key={key}
                title={faq.question}
                description={faq.answer}
              />
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    // If not logged in
    return (
      <div className="logon-screen">
        <img className="teamskip-logo-icon" alt="" src="/teamskiplogo.svg" />
        <div className="main-frame">
          <div className="input-frame">
            {maxRetriesHit ? (
              <div className="reconnect-area" onClick={handleRefresh}>
                Reconnect
              </div>
            ) : (
              <>
                <input
                  className="input"
                  type="email"
                  value={user_message}
                  onChange={
                    verifyingStage
                      ? handleInputChangeCode
                      : handleInputChangeEmail
                  }
                  onKeyDown={
                    verifyingStage ? addMessageEnterCode : addMessageEnterEmail
                  }
                  placeholder={placeholder}
                />
                <button
                  className={`go-button ${
                    isValidEmail || isValidCode ? 'go-button-active' : ''
                  }`}
                  onClick={addMessage}
                  disabled={!isValidEmail && !isValidCode && !canSendMessage}
                  style={{
                    cursor:
                      isValidEmail || isValidCode ? 'pointer' : 'not-allowed',
                  }}
                >
                  <div className="go">{verifyingStage ? 'Verify' : 'Go'}</div>
                </button>
              </>
            )}
          </div>
          <div className="text-frame">
            <div className="backend-response">
              {backend_response ? (
                backend_response
              ) : (
                <div className="animated-text">
                  <span>Enter</span> <span>your</span> <span>email</span>{' '}
                  <span>above</span> <span>to</span> <span>register</span>{' '}
                  <span>or</span> <span>sign</span> <span>in ...</span>
                </div>
              )}
            </div>
          </div>
          <div className="tabs-frame">
            {tabs.map((tab, index) => (
              <div key={index} className="tab">
                <div className="tab-inner">
                  <div className="tab-front">
                    <img className="icon" alt="" src="/tab-icon.svg" />
                    <div className="tab-text">{tab.label}</div>
                  </div>
                  <div className="tab-back">
                    <div className="tab-text">{tab.description}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state: IAppState) => {
  return {
    loading: state.chats.loading,
    error: state.chats.error,
    errorMessage: state.chats.errorMessage,
    chats: state.chats.chats,
    firstName: state.users.firstName,
    lastName: state.users.lastName,
    loggedIn: state.users.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getChats: () => dispatch(actionCreators.getChats()),
    logout: () => dispatch(actionCreators.logout()),
    newRefresh: () => dispatch(actionCreators.getRefreshToken()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
